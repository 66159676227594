/*/
	MWG Corporate Plugins
	Stephen Ginn at Crema Design Studio
	---
	Last Updated 2019-03-18
	@license MIT
/*/

// Cremastrap Plugins
// @codekit-append "js/compat.js";
// @codekit-append "js/tls-support.js";
// @codekit-append "js/tabmodal-links.js";
// @codekit-append "js/tabmodal-links-v2.js";
// @codekit-append "js/custom-controls.js";
// @codekit-append "captcha.min.js";
// @codekit-append "js/functions/environment.js";
// @codekit-append "js/formcarry.js";

// Third Party Plugins
// @codekit-append "jquery-mask/dist/jquery.mask.js";
// @codekit-append "slick-slider/1.5.0/slick.js";
// @codekit-append "svg-injector/svg-injector.js";
