// jshint strict:false, undef:false, unused:false

/*!
	@name:		BS3 Custom Controls
	@version:	0.22
	@author:	Stephen Ginn
	@changed: 	2019-05-17

	Updated markup is needed for styling active checkbox replacements.
	This helper script adds BS4 classes and html markup to old BS3 forms.
	However, this script can be removed if you manually upgrade your forms.

	Converts this...
	<div class="checkbox m-b">
		<label>
			<input type="checkbox"> Your Checkbox
		</label>
	</div>

	...to this:
	<div class="checkbox m-b">
		<label class="custom-control custom-checkbox">
			<input type="checkbox" class="custom-control-input">
			<div class="custom-control-label">Your Checkbox</div>
		</label>
	</div>
    
    And this:
    <label class="radio-inline">
        <input type="radio"> Your Radio
    </label>
    
    ...to this:
    <label class="radio-inline">
        <input type="radio">
        <div class="custom-control-label">Your Radio</div>
    </label>
    
    <label class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input">
        <div class="custom-control-label">Your Radio</div>
    </label>

	HOW TO INITIALIZE
	custom_controls();
/*/


function custom_controls() {
    var selectors = ".checkbox label input, .checkbox-inline input, .radio label input, .radio-inline input, select",
        inputs = document.querySelectorAll(selectors);

	for (var i = 0; i < inputs.length; i++) {
		var input = inputs[i];

		if (input.type === "select-one") {
			input.classList.add("custom-control");
		} else {
			var label = input.parentElement;

			input.classList.add("custom-control-input");
			label.classList.add("custom-control", "custom-" + input.type);

			var inputHTML = input.outerHTML;
			label.removeChild(input);
			label.innerHTML = inputHTML + '<div class="custom-control-label">' + label.innerHTML + '</div>';
		}
	}
}
