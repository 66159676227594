// jshint strict:false, undef:false, unused:false

/**
	Get URL Parameters with Pure Javascript
	Based on http://stackoverflow.com/questions/19491336

	Usage: https://example.com/?name=bob
/*/

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}
