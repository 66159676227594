/* jshint strict:false */

/**
	Bootstrap Tabs and Modal Linking v1
	Stephen Ginn at Crema Design Studio
	Modified 2016-03-08

	Adapted from the flynfish solution on:
	http://stackoverflow.com/questions/7862233

	Usage: example.com/#modal_success OR example.com/#tab_pricing
	NOTE: Using an IIFE function here will break the Bootstrap fade animation
/*/

$(function() {
	var hash = window.location.hash, tab_prefix = "tab_", tabHash;

	if (hash.search(tab_prefix) > -1) {
		tabHash = hash.replace(tab_prefix,"");
		$('.nav-tabs a[href='+tabHash+'],.nav-pills a[href='+tabHash+']').tab('show');
	} else if (document.querySelector(hash).classList.contains('modal')) {
		$(hash).modal('show');
	}

	// Change hash for page-reload. Seems to have an issue with urls that don't contain hashes.
	$('.nav-tabs a').on('shown', function (e) {
		window.location.hash = e.target.hash.replace("#", "#" + tab_prefix);
	});
});
