// jshint strict:false, undef:false, unused:false

/**
	Multi-Environment v2.22
	Updated on 2019-02-25

	This script sets a data string and a localStorage variable
	to indicate	which environment the site is being viewed from.
/*/

(function () {
	function contains(string) {
		return location.href.indexOf(string) > -1;
	}

	function getEnv() {
		if (contains(".dev") || contains(".test") || contains("file://")) {
			return 'local';
		} else if (contains("cremadesignstudio")) {
			return 'proof';
		} else if (contains("dev.")) {
			return 'staging';
		} else {
			return 'live';
		}
	}

	document.body.setAttribute("data-env", getEnv());
	localStorage.setItem("env",  getEnv());
})();
