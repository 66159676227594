
/**
	Bootstrap Tabs and Modal Linking v2
	Stephen Ginn at Crema Design Studio

	Usage: example.com/?modal=success OR example.com/?tab=pricing
/*/

// @codekit-prepend "functions/getUrlParameter.js";

function tabModalLinks() {
	var modal = getUrlParameter('modal'), tab = getUrlParameter('tab');

	if (modal !== undefined) {
		$('#modal-' + modal).modal('show');
	}

	if (tab !== undefined) {
		$('a[href="#tab-' +tab+ '"]').tab('show');
	}
}

window.addEventListener("load", tabModalLinks);
