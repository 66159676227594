// jshint strict:false, undef:false, unused:false

/**
	TLS Support Script
	This auto-initializing script inserts an unsupported browser alert
	if a user's browser does not support TLS 1.1 or 1.2.
	Based on https://kb.blackbaud.com/articles/Article/115882

	Supported Browser Versions
	- Chrome: 19.77.34.5
	- Firefox: 28.0
	- Safari: 5.1.7
	- Internet Explorer: 11.0

	Crema's Changes
	- Converted from an Online Express Code snippet to pure JS IIFE function
	- Added custom alert messages and Bootstrap v3/v4 classes
	- Removed wrapper, since we're automatically adding that.
	- Added debugging messages

	Last Updated 2019-05-09
/*/


(function () {
	// Set formDebug to true ONLY when developing the site!
	var formDebug = true,
		msg = 'You are using an <strong>unsupported and insecure</strong> browser. Please <a href="https://browsehappy.com/" target="_blank" rel="noopener">upgrade your browser</a> to improve your experience and security.';

	var userAgent = window.navigator.userAgent.toLowerCase(),
		userAgentVersion = 0,
		supportForTLS = false,
		engine = "Unknown";

	// Internet Explorer 11 and lower (MSIE test)
	if (userAgent.indexOf('msie ') > -1) {
		userAgentVersion = Number(userAgent.split('msie ')[1].split('.')[0]);
		engine = "MSIE";
		if (userAgentVersion > 10) {
			supportForTLS = true;
		}
	// Internet Explorer (some versions, but checking for 11+) (Trident test)
	} else if (userAgent.indexOf('trident/') > -1) { // Internet Explorer (by Trident if MSIE is missing)
		userAgentVersion = Number(userAgent.split('trident/')[1].split('.')[0]);
		engine = "Trident";
		if (userAgentVersion > 6) {
			supportForTLS = true;
		}
	// Chrome, Edge and Safari
	} else if (userAgent.indexOf('applewebkit/') > -1) {
		userAgentVersion = Number(userAgent.split('applewebkit/')[1].split('.')[0]);
		engine = "Webkit";
		if (userAgentVersion > 536) {
			supportForTLS = true;
		}
	// Firefox
	} else if (userAgent.indexOf('firefox/') > -1) {
		userAgentVersion = Number(userAgent.split('firefox/')[1].split('.')[0]);
		engine = "Firefox";
		if (userAgentVersion > 26) {
			supportForTLS = true;
		}
	// Opera
	} else if (userAgent.indexOf('opera/') > -1) {
		engine = "Opera"; userAgentVersion = Number(userAgent.split('opera/')[1].split('.')[0]);
		if (userAgentVersion > 13) {
			supportForTLS = true;
		}
	}

	if (formDebug) {
		console.log(engine + " Version: " + userAgentVersion);
		console.log("User Agent: " + userAgent);
		console.log("TLS Support: " + supportForTLS);
	}

	var html = document.documentElement;

	document.addEventListener('DOMContentLoaded', function () {
		if (!supportForTLS) {
			var body = document.body,
				alertDiv = document.createElement('div');

			alertDiv.className = "alert alert-danger alert-browser";
			alertDiv.innerHTML = '<i class="fa fa-exclamation-circle"></i> ' + msg;
			body.insertBefore(alertDiv, body.firstChild);
			html.className += " no-tls-support";
		} else {
			html.className += " tls-support";
		}
	}, false);
})();
